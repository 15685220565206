import React, { useState } from 'react';
import './EmloyeeNavContainar.css';  
import { FaBeer } from 'react-icons/fa';
 
import { IoArrowBack } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
const EmloyeeNavContainar = ({ oneditItem, onExit ,name}) => {
 
  

  return (

    
    <div className="search-container2">
          <button onClick={onExit} className="exit-button">   
          <div className='ioarrow' >
            <IoArrowBack />
            </div>
              Zurück</button>

              <div className='name-and-profile ' >
           
              <div className='ioarrow' >
          <IoPersonOutline />
            </div>
            <span>{name}</span>
              <div className='profile' >
          <button onClick={oneditItem} className="profil-button">

      
       
            Profil bearbeiten</button>
            </div>
            </div>
  
  
    </div>
  );
};

export default EmloyeeNavContainar;
