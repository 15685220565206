import React from 'react';
 
import Card from '../StaticItems/Card';
import './Homepage.css';


function Homepage() {
  const werkstattItems = ['Personalplanung', 'Zeitmanagement', 'Externe Aufträge'];
  const mitarbeiterItems = ['Arbeitszeiten', 'Urlaubsplan', 'Kontaktlisten'];
  const mietwagenItems = ['Arbeitszeiten', 'Urlaubsstegen + Fehltage', 'Kontaktlisten + Regeln'];
  const checklistenItems = ['Fahrzeugannahme', 'Fahrzeugübergabe', 'Checkliste Scheibentönung', 'Checkliste Lackschutz', 'Checkliste Externe Aufträge'];
  const vertriebstoolItems = ['Angebot', 'Auftragsbestätigung', 'Rechnungs', 'ToDo List', 'Kalender/Termin Planer'];
  const verkaufsfoerderungItems = ['Flyer, Visitenkarten, Plakate', 'PVC-Planen, Banner, Fahnen', 'Leuchtwerbungen', 'Promotion'];

  return (
    <>
   
      <div className="grid-container">
        <Card number="3" title="Werkstattplanung" items={werkstattItems} navigateTo="/werkstattplanung" />
        <Card number="8" title="Mitarbeiter" items={mitarbeiterItems} navigateTo="/mitarbeiter" />
        <Card number="0" title="Mietwagen" items={mietwagenItems} navigateTo="/mietwagen" />
        <Card number="0" title="Checklisten" items={checklistenItems} navigateTo="/checklisten" />
        <Card number="0" title="Vertriebstool" items={vertriebstoolItems} navigateTo="/vertriebstool" />
        <Card number="0" title="Verkaufsförderung" items={verkaufsfoerderungItems} navigateTo="/verkaufsfoerderung" />
      </div>
    </>
  );
}

export default Homepage;
