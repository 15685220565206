import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { fetchData } from '../../axios_URL';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './EmployeeCalendar.css';
import "rsuite/dist/rsuite.css";
import { Calendar, Checkbox } from 'rsuite';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const EmployeeCalendar = ({ id }) => {
  const [vacations, setVacations] = useState([]);
  const [importantDays, setImportantDays] = useState([]);
  const [requests, setRequests] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [selectedRequests, setSelectedRequests] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [reason, setReason] = useState('');
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const [vacationdetails, setVacationdetails]  =  useState(null);
 
  // Fetch all the necessary data on component mount
  useEffect(() => {
    // Calculate the dynamic date range (last year to next year)
    const fetcheCalendarData = async () => {
      const currentMonthStart = currentMonth.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
      const currentMonthEnd = currentMonth.endOf('month').format('YYYY-MM-DDTHH:mm:ss');
        
    // Fetch employee vacations
    fetchData('get', `/test/employeevacations/${id}?startDate=${currentMonthStart}&endDate=${currentMonthEnd}`)
      .then(res => setVacations(res.data))
      .catch(err => console.error(err));

    // Fetch employee requests for the current month
 
    fetchData('get', `/test/employeerequests/${id}?startDate=${currentMonthStart}&endDate=${currentMonthEnd}`)
      .then(res => setRequests(res.data))
      .catch(err => console.error(err));

    // Fetch important days (assuming this is independent of date range)
    fetchData('get', `/test/importantday`)
      .then(res => setImportantDays(res.data))
      .catch(err => console.error(err));

    // Fetch pending requests (no date range)
    fetchData('get', `/test/employeependinrequests/${id}`)
      .then(res => setPendingRequests(res.data))
      .catch(err => console.error(err));

      const date = dayjs().endOf('year').format('YYYY-MM-DDTHH:mm:ss'); 
      fetchData('get', `/test/remaining/${id}?date=${date}`)
        .then(res => setVacationdetails(res.data))
        .catch(err => console.error(err));
      
        console.log(vacationdetails)
 
    };
    fetcheCalendarData();
  }, [currentMonth,id]);


  const handleSelectRequest = (requestId) => {
    setSelectedRequests(prev =>
      prev.includes(requestId) ? prev.filter(id => id !== requestId) : [...prev, requestId]
    );
  };

  // Handle Select All toggle
  const handleSelectAll = () => {
    if (selectedRequests.length === pendingRequests.length) {
      setSelectedRequests([]); // Deselect all
    } else {
      setSelectedRequests(pendingRequests.map(req => req.requestID)); // Select all
    }
  };

  // Show modal for action (approve/reject)
  const handleShowModal = (action) => {
    setActionType(action);
    setModalVisible(true);
  };

 
 
  const handleAction = async () => {
    try {
      for (const requestId of selectedRequests) {
        const url = actionType === 'approve'
          ? `/test/approverequest/${requestId}?reason=${encodeURIComponent(reason)}`
          : `/test/rejectrequest/${requestId}?reason=${encodeURIComponent(reason)}`;



        await fetchData('post', url);
      }
      setModalVisible(false);
      setSelectedRequests([]); // Clear selection after action
      // Optionally refetch data
    } catch (error) {
      console.error("Error processing request: ", error);
    }
  };













  // Check if a date is a vacation, request, or important day
  const getCellClass = (date) => {
    const dateString = dayjs(date).format("YYYY-MM-DD");

    // Important day check
    if (date.getDay() === 0) {
      return 'weekend-cell';
    }

    // Vacation check
    const vacation = vacations.find(v => dayjs(date).isBetween(v.startDate, v.endDate, 'day', '[]'));
    if (vacation) {
      return 'vacation-cell';
    }
    if (importantDays.some(day => dayjs(day.date).format("YYYY-MM-DD") === dateString)) {
      return 'importantday-cell';
    }
    // Request check
    const request = requests.find(r => {
      // Handle requests with startDate and endDate (range)
      if (r.startDate && r.endDate) {
        return dayjs(date).isBetween(r.startDate, r.endDate, 'day', '[]');
      }
      // Handle requests with extraTimeDate (single day)
      if (r.extraTimeDate) {
        return dayjs(r.extraTimeDate).format("YYYY-MM-DD") === dateString;
      }
      return false;
    });

    if (request) {
      switch (request.requestStatus) {
        case 'Pending': return 'request-pending-cell';
        case 'Approved': return 'request-approved-cell';
        case 'Rejected': return 'request-rejected-cell';
        default: return '';
      }
    }

    // Weekend check


    return '';  // Default
  };

  // Handle clicking on pending requests to navigate to the request date
  const handlePendingRequestClick = (startDate) => {
    const formattedDate = dayjs(startDate).format("YYYY-MM-DD");
    const cell = document.querySelector(`.rs-calendar-table-cell-${formattedDate}`);

    // Check if the cell exists before trying to scroll to it
    if (cell) {
      cell.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn(`No cell found for the date ${formattedDate}`);
    }
  };


  const handleRequestClick = (req) => {
    setSelectedRequest(req);
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedRequest(null);
  };

  
  const handleMonthChange = (date) => {
    setCurrentMonth(dayjs(date));
  };





  return (
    <div className='allcalendar'>
      <div className='mycalendar'>
        <Calendar
          bordered
          compact
          defaultValue={new Date()}
          cellClassName={(date) => getCellClass(date)}
          onChange={handleMonthChange}
        />
      </div>
      <div className='requestcontainer'>
  {vacationdetails ? (
    <>
      {Math.round((vacationdetails.vacationdays - (vacationdetails.allvacationstaken / 8.0)))} Tage Übrig von {vacationdetails.vacationdays} Tage. Jahr { dayjs().year()}
    </>
  ) : (
    <p>Loading vacation details...</p>
  )}
  
  <div className="select-buttons">
    <Checkbox onClick={handleSelectAll}>
      {selectedRequests.length === pendingRequests.length ? 'Deselect All' : 'Select All'}
    </Checkbox>
  </div>
 

      <div className='requests'>
       
        {pendingRequests.length ? (
          <>
    
            <ul>
          
              {pendingRequests.map(req => (
                <li key={req.requestID}>
                  <input
                    type="checkbox"
                    checked={selectedRequests.includes(req.requestID)}
                    onChange={() => handleSelectRequest(req.requestID)}
                  />
                  {console.log(req)}
                  < div onClick={() => handleRequestClick(req)}>
                  {req.reason} - {req.timeOfRequest}
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>No pending requests</p>
        )}
      
      </div>
      <div className="action-buttons">
          <button className="Approvebutton" onClick={() => handleShowModal('approve')} disabled={!selectedRequests.length}>
            Approve  
          </button>
          <button className="Rejectbutton" onClick={() => handleShowModal('reject')} disabled={!selectedRequests.length}>
            Reject  
          </button>
        </div>
      </div>


      
      {modalVisible && (
  <div className="modal-reason">
    <div className="modal-content-reason">
      <h3>{actionType === 'approve' ? 'Approve Request' : 'Reject Request'}</h3>
      
      {actionType === 'reject' && (
        <textarea
          placeholder="Enter reason for this action"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      )}
      
      <div className="modal-actions-reason">
        <button onClick={handleAction}>
          Confirm {actionType === 'approve' ? 'Approval' : 'Rejection'}
        </button>
        <button onClick={() => setModalVisible(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}

{isPopupOpen && selectedRequest && (
        <div className="popup-details">
          <div className="popup-content-details">
            <h3>Request Details</h3>
            {Object.entries(selectedRequest).map(([key, value]) => (
              <p key={key}>
                <strong>{key}:</strong> {value?.toString()}
              </p>
            ))}
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}







    </div>
  );
};

export default EmployeeCalendar;
