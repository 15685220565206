import React, { useState, useEffect } from 'react';
import './AddEmployee.css';
import { fetchData } from "../../axios_URL";

const AddEmployee = ({ onClose, onSave, updateid }) => {
   
  const [errors, setErrors] = useState({});
  const [role, setRole] = useState('');
  const defaultSchedules = [
    { dayOfWeek: 'MONDAY', startTime: '08:00:00', endTime: '17:00:00', breakStartTime: '12:00:00', breakDuration: 60, workingHours: 8, offDay: false },
      { dayOfWeek: 'TUESDAY', startTime: '08:00:00', endTime: '17:00:00', breakStartTime: '12:00:00', breakDuration: 60, workingHours: 8, offDay: false },
      { dayOfWeek: 'WEDNESDAY', startTime: '08:00:00', endTime: '17:00:00', breakStartTime: '12:00:00', breakDuration: 60, workingHours: 8, offDay: false },
      { dayOfWeek: 'THURSDAY', startTime: '08:00:00', endTime: '17:00:00', breakStartTime: '12:00:00', breakDuration: 60, workingHours: 8, offDay: false },
      { dayOfWeek: 'FRIDAY', startTime: '08:00:00', endTime: '17:00:00', breakStartTime: '12:00:00', breakDuration: 60, workingHours: 8, offDay: false },
     { dayOfWeek: 'SATURDAY', startTime: '', endTime: '', breakStartTime: '', breakDuration: '', workingHours: '', offDay: true },
      { dayOfWeek: 'SUNDAY', startTime: '', endTime: '', breakStartTime: '', breakDuration: '', workingHours:'', offDay: true },
 
];
  const [employee, setEmployee] = useState({
    firstname: '',
    lastname: '',
    birthDate: '',
    nationality: '',
    telephone_number: '',
    email: '',
    department: '',
    jobTitle: '',
    person_number: '',
    vacationdays: '',
    schedules: defaultSchedules
  });


  const days = ['Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.', 'So.'];


  useEffect(() => {
    if (updateid) {
      // Fetch existing employee data if updateid is provided
      fetchData('GET', `/test/employees/${updateid}`)
        .then(response => {
          setEmployee(prevEmployee => ({
            ...prevEmployee,
            ...response.data, // Spread the response to merge with the current state
            schedules: response.data.schedules || prevEmployee.schedules // Use fetched schedules or fallback
          }));
        })
        .catch(error => console.error('Error fetching employee data:', error));
    }
  }, [updateid]);

  
  
  
 


  // Reusable input field renderer with validation
  const renderInputField = (label, name, type = 'text', disabled = false) => (
    <div className="form-group">
      <label>{label} <span style={{ color: 'red' }}>*</span></label>
      <input 
      type={type}
       name={name} 
       value={employee[name] || ''} 
       onChange={handleChange}
       disabled={disabled}
        />
        
      {errors[name] && <p className="error">{errors[name]}</p>}

    </div>
  );
  const space = () => (
    <div className="form-group">

    </div>
  );

  const renderScheduleFields = () => {
    if (!employee.schedules || employee.schedules.length === 0) {
      return <p>No schedules available.</p>; // Provide a fallback message
    }
    return (
      <div>
    
  
        {/* Labels for Fields (only displayed once) */}
        {employee.schedules.length > 0 && (
          <div className="schedule-fields">
            <label>        </label>
            <label>        </label>
            {/* Working Hours */}
            <label>Working Hours</label>
            {/* Start Time */}
            <label>Start Time</label>
            <label>End Time</label>
            <label>Break Start Time</label>
            <label>Break Duration (minutes)</label>
            <label>Is Off Day</label>
          </div>
        )}
  
        {/* Schedule Fields */}
        {employee.schedules.map((schedule, index) => (
          <div className="schedule-fields" key={index} style={{ opacity: schedule.offDay ? 0.5 : 1 }}>
            {/* Day of the Week */}
            <div className="labelofday">
              <p>{days[index]}</p> {/* Use the days array here */}
            </div>
  
            {/* Combined Label for Working Hours */}
            <div className="form-group">
              <label>
                {Math.floor(schedule.workingHours).toString().padStart(2, '0')}:
                {Math.round((schedule.workingHours % 1) * 60).toString().padStart(2, '0')}
              </label>
            </div>
  
            {/* Start Time */}
            <div className="form-group">
              <input
                type="time"
                step="900" // Step is 15 minutes (900 seconds)
                name={`startTime-${index}`}
                value={schedule.offDay ? '' : schedule.startTime} // Set to empty if off day
                onChange={(e) => !schedule.offDay && handleScheduleChange(index, 'startTime', e.target.value)}
                disabled={schedule.offDay} // Disable if off day
              />
            </div>
  
            {/* End Time */}
            <div className="form-group">
              <input
                type="time"
                name={`endTime-${index}`}
                value={schedule.offDay ? '' : schedule.endTime} // Set to empty if off day
                onChange={(e) => !schedule.offDay && handleScheduleChange(index, 'endTime', e.target.value)}
                disabled={schedule.offDay} // Disable if off day
              />
            </div>
  
            {/* Break Start Time */}
            <div className="form-group">
              <input
                type="time"
                name={`breakStartTime-${index}`}
                value={schedule.offDay ? '' : schedule.breakStartTime} // Set to empty if off day
                onChange={(e) => !schedule.offDay && handleScheduleChange(index, 'breakStartTime', e.target.value)}
                disabled={schedule.offDay} // Disable if off day
              />
            </div>
  
            {/* Break Duration */}
            <div className="form-group">
              <input
                type="number"
                name={`breakDuration-${index}`}
                value={schedule.offDay ? '' : schedule.breakDuration} // Set to empty if off day
                onChange={(e) => !schedule.offDay && handleScheduleChange(index, 'breakDuration', e.target.value)}
                disabled={schedule.offDay} // Disable if off day
              />
            </div>
  
            {/* Is Off Day */}
            <div className="form-group">
              <input
                type="checkbox"
                name={`isOffDay-${index}`}
                checked={schedule.offDay}
                onChange={(e) => handleScheduleChange(index, 'offDay', e.target.checked)}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };
  

  
  const handleScheduleChange = (index, field, value) => {
    const updatedSchedules = [...employee.schedules];
    updatedSchedules[index] = {
      ...updatedSchedules[index],
      [field]: value,
    };
  
    // Calculate workingHours if startTime or endTime is changed
    if (field === 'startTime' || field === 'endTime') {
      const startTime = updatedSchedules[index].startTime;
      const endTime = updatedSchedules[index].endTime;
  
      if (startTime && endTime) {
        const start = new Date(`1970-01-01T${startTime}:00`);
        const end = new Date(`1970-01-01T${endTime}:00`);
        
        // Calculate difference in hours
        const diffInMs = end - start;
        const diffInHours = Math.max(diffInMs / (1000 * 60 * 60), 0); // ensure non-negative
  
        updatedSchedules[index].workingHours = diffInHours;
      }
    }
  
    setEmployee({ ...employee, schedules: updatedSchedules });
  };
  


  // Handle form submission with validation
  const handleSubmit = (e) => {
    if (updateid) {
      e.preventDefault();
      if (validateForm()) { 
        const apiEndpoint = role === 'ADMIN' ? `/test/admin/${updateid}` : `/test/employees/${updateid}`;
        onSave(e, employee, apiEndpoint);
      }
    }else{
    e.preventDefault();
    if (validateForm()) {
      const apiEndpoint = role === 'ADMIN' ? '/test/admin' : '/test/employees';
      onSave(e, employee, apiEndpoint);
    }
  }

  };

  // Handle form validation
  const validateForm = () => {
    const newErrors = {};
    Object.keys(employee).forEach((key) => {
      if (!employee[key]) newErrors[key] = `${key} is required`;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };
  const handleChangerole = (e) => {
    setRole(e.target.value); // Directly setting the selected value
  };

  return (
    <div className="modal1">
      <div className="modal-content">
        <div className="form-container">
          <div className='AddEmployeeHeader'>
           Neuer Mitarbeiter Hinzufügen  
          </div>
          <form onSubmit={handleSubmit} >
            <div className="employee-form"> 
            <div className="form-group">
              <label>
                Role <span style={{ color: 'red' }}>*</span>
              </label>
              <select name="role" value={role} onChange={handleChangerole}>
                <option value="USER">Mitarbeiter</option>
                <option value="ADMIN">Admin</option>
              </select>

            </div>
            {space()}
            {renderInputField('Vorname', 'firstname')}

            {renderInputField('Nachname', 'lastname')}
            {renderInputField('Geburtsdatum', 'birthDate', 'date')}
            {renderInputField('Geburtsort', 'nationality')}
            {renderInputField('Handynummer', 'telephone_number')}

            {renderInputField('Email-Adresse', 'email', 'email', !!updateid)}
            {space()} 
            {renderInputField('Abteilung', 'department')}
            {renderInputField('Tätigkeit', 'jobTitle')}
            {renderInputField('Einstellungsdatum', 'hireDate', 'date')}
            {renderInputField('Personalnummer', 'person_number')}
            {renderInputField('Urlaupstage', 'vacationdays')}
             </div>

        <div className='schedules'>
            {renderScheduleFields()}
            </div>
            <div className="button-container">
              <button type="button" onClick={onClose} className="close-button">Schließen</button>
              <button type="submit" className="save-button">Speichern</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
