import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
  
 
 
import    Login   from "./Pages/Auth/Login";
import   Logout  from "./Pages/Auth/Logout";
import   Mitarabeiter  from "./Pages/Mitarabeiter/Mitarabeiter";
import   Homepage  from "./Pages/Home/Homepage";
import Nav from './Pages/Navbar/Nav';
 import Employee from './Pages/Mitarabeiter/Employee';
 import EditMyDetails from './Pages/Personal/EditMyDetails';
import { fetchData } from './axios_URL';
function App() {
    const [isAuthenticated, setIsAuthenticated] = useState();
 
 
 
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchData('GET', '/check-auth', { Authorization: `Bearer ${token}` })
        .then(response => {
          setIsAuthenticated(true);
        })
        .catch(error => {
          setIsAuthenticated(false);
        })
        .finally(() => {
          // Set isLoading to false after 0.5 seconds
          setTimeout(() => {
       
          }, 500);
        });
    } else {
      setIsAuthenticated(false);
  
    }
  }, []);

 
  return (
    <div  >



      <Router>
        
      
        {isAuthenticated ? (
          <div>
              <Nav />
          <Routes>
          EditMyDetails
            <Route exact path="/mitarbeiter/:id" element={<Employee />} />
            <Route exact path="/mitarbeiter" element={<Mitarabeiter />} />
            <Route exact path="/EditMyDetails" element={<EditMyDetails />} />
              <Route exact path="/logout" element={<Logout onLogout={() => setIsAuthenticated(false)} />} />
              <Route path="*" element={<Homepage isAuthenticated={isAuthenticated} />} />
          </Routes>
          </div>
        ) : (
          <Routes>
             
       
        
                <Route path="*" element=  {<Login onLogin={() => setIsAuthenticated(true)} />} />
          </Routes>
        )}
    
      </Router>
       
       </div>
  );
}

export default App;