import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { fetchData } from '../../axios_URL';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './EmployeeScheduleTable.css';

const EmployeeScheduleTable = ({ id, isCalendarVisible }) => {
  const [schedule, setSchedule] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  let allWorkingHours = 0;
  let allWorkingHoursshould = 0;
  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        const startDate = currentMonth.startOf('month').format('YYYY-MM-DD');
        const endDate = currentMonth.endOf('month').format('YYYY-MM-DD');
        const response = await fetchData(
          'GET',
          `/test/schedule/employee/${id}?startDate=${startDate}&endDate=${endDate}`
        );
        setSchedule(response.data);
      } catch (error) {
        console.error('Error fetching schedule data:', error);
      }
    };

    fetchScheduleData();
  }, [currentMonth, id]);

  const getAllDaysInMonth = (month) => {
    const daysInMonth = month.daysInMonth();
    const dates = [];
    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(month.date(i));
    }
    return dates;
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(prev => prev.subtract(1, 'month'));
  };

  const goToNextMonth = () => {
    setCurrentMonth(prev => prev.add(1, 'month'));
  };
  const formatWorkingHours = (workingHours) => {
    if (typeof workingHours !== 'number') {
      return ''; // Return empty string for invalid input
    }

    const hours = Math.floor(workingHours).toString().padStart(2, '0');
    const minutes = Math.round((workingHours % 1) * 60).toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };
  const renderTableData = () => {
    const allDays = getAllDaysInMonth(currentMonth);
    const days = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'];



    return allDays.map((day) => {
      const daySchedule = schedule?.find(s => dayjs(s.date).isSame(day, 'day'));
      const weekDay = days[day.day()];



      // If daySchedule exists and workingHours is a valid number, add it to allWorkingHours
      if (daySchedule?.workingHours) {
        allWorkingHours += daySchedule.workingHours;
      }
      if (daySchedule?.shouldworkingHours) {
        allWorkingHoursshould += daySchedule.shouldworkingHours;
      }
      return (
        <tr key={day.format('DD.MM.YYYY')}>
          <td>{weekDay} {day.format('DD.MM.YYYY')}</td>
          <td>{daySchedule?.timeIn || ' '}</td>
          <td>{daySchedule?.startTime || ' '}</td>
          <td>{daySchedule?.timeOut || ' '}</td>
          <td>{daySchedule?.endTime || ' '}</td>
          <td>
            {formatWorkingHours(daySchedule?.workingHours)}
          </td>

          <td>{daySchedule?.shouldworkingHours || ''}</td>
          <td>{daySchedule?.breaktime || ''}</td>

          <td>{daySchedule?.status || ' '}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="month-navigation">
        <div className='month-navigation-icons'>
          <span onClick={goToPreviousMonth} style={{ cursor: 'pointer' }}>
            <IoIosArrowBack />
          </span>
          <span onClick={goToNextMonth} style={{ cursor: 'pointer' }}>
            <IoIosArrowForward />
          </span>
        </div>
        <p>{currentMonth.format('MMMM YYYY')}</p>
      </div>

      {/* Table container for static height and scroll */}

      <div className={`table-container ${!isCalendarVisible ? 'slide-up' : ''}`}>

        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time In</th>
              <th>Start Time</th>
              <th>Time Out</th>
              <th>End Time</th>
              <th>Working Hours</th>

              <th>Should Work Hours</th>
              <th>Break Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {renderTableData()}
          </tbody>
          <tfoot>
            <tr>
              <th> </th>
              <th> </th>
              <th> </th>
              <th> </th>
              <th>
                {allWorkingHours < allWorkingHoursshould ? (
                  <button
                    className= "totalbuttonred"
                  >
                   {formatWorkingHours(allWorkingHoursshould - allWorkingHours)}
                  </button>
                ) : null}
              </th>

              <th>
                <button
                  className="alldaysbutton"
                >
                  {formatWorkingHours(allWorkingHours)}
                </button>

              </th>

              <th>
              
                {allWorkingHours > allWorkingHoursshould ? (
                <button
                className= "totalbuttongreen"
              >
            
          
                   {formatWorkingHours(  allWorkingHours -allWorkingHoursshould)}
                  </button>
                ) : null}
              </th>
              <th> </th>
              <th>     <button className='alldaysbutton'>
                {dayjs().format('D')} days
              </button></th>
            </tr>
          </tfoot>
        </table>



      </div>

      <div className='container-totalbuttons'>


      </div>

    </>
  );
};

export default EmployeeScheduleTable;


