import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {useLocation, useNavigate } from 'react-router-dom';

import EmloyeeNavContainar from '../StaticItems/EmloyeeNavContainar';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import './Employee.css';
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";

import EmployeeScheduleTable from '../StaticItems/EmployeeScheduleTable '; // Import the table component
import EmployeeCalendar from '../StaticItems/EmployeeCalendar';
import EmployeePaper from '../StaticItems/EmployeePaper';
import { fetchData } from "../../axios_URL";
import AddEmployee from './AddEmployee';

dayjs.extend(advancedFormat);

const Employee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCalendarVisible, setIsCalendarVisible] = useState(true);

  const location = useLocation();
  const [showModal, setShowModal] = useState(false);  
  const [error, setError] = useState("");
  const thename = location.state?.thename || [];
 

  const handleExit = () => {
    navigate("/mitarbeiter");
  };

  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(prev => !prev); // Toggle visibility
  };
  const handleUpdateEmployee = async (event, employee, apiEndpoint) => {
    event.preventDefault();

    try {
      const response = await fetchData(
        "PUT",
        apiEndpoint,
        employee,
        setError
      );

      console.log("Employee saved successfully:", response.data);
      setShowModal(false); // Hide modal
    } catch (error) {
      setError("Fehler beim Speichern des Mitarbeiters");
    }
  };

  const handleAddItem = () => {
    setShowModal(true); // Show modal on button click
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide modal
  };

  return (
    <>
      <div className='SearchContainer'>
        <EmloyeeNavContainar onExit={handleExit} oneditItem={handleAddItem} name={thename}  />
      </div>
      <div className="container">
        {/* Icon to toggle calendar visibility */}

        {/* EmployeeCalendar in a row, split into three sections */}

        <div className="employee-calendar">
          <div className={`employee-calendar-container ${!isCalendarVisible ? 'hidden' : ''}`}>
            <EmployeeCalendar id={id} className="employee-calendar" />
            <div className="employee-calendar-container-message">
           <h3>Chat</h3>
           <EmployeePaper id={id}   />

            </div>
          </div>

        </div>
      </div>
      <div onClick={toggleCalendarVisibility} style={{ cursor: 'pointer', textAlign: 'center' }}>
        {isCalendarVisible ? <IoMdArrowDropdown className={`icon ${isCalendarVisible ? "rotate" : ""}`} /> : <IoMdArrowDropup className={`icon ${!isCalendarVisible ? "rotate" : ""}`} />}
      </div>
      <div className="container">
        {/* EmployeeScheduleTable taking full row below */}
        <div className={`scheduleTablecontainer ${!isCalendarVisible ? 'slide-up' : ''}`}>
          <EmployeeScheduleTable id={id} isCalendarVisible={isCalendarVisible} />
        </div>
     
      </div>
      {showModal && (
        <AddEmployee onClose={handleCloseModal} onSave={handleUpdateEmployee} updateid={id} />
      )}
    </>
  );
};

export default Employee;
