import React, { useState } from 'react';
import './SearchContainer.css';  
import { FaBeer } from 'react-icons/fa';
import { TbUserPlus } from "react-icons/tb";
import { IoArrowBack } from "react-icons/io5";
const SearchContainer = ({ onAddItem, onExit }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (

    
    <div className="search-container">
          <button onClick={onExit} className="exit-button">   
          <div className='ioarrow' >
            <IoArrowBack />
            </div>
              Zurück</button>
          <button onClick={onAddItem} className="add-item-button">

          <div className='ioarrow' >
          <TbUserPlus />
            </div>
            Neuer Mitarbeiter hinzufügen</button>

            <button onClick={onAddItem} className="add-item-button">Urlaubs- und Feiertage</button>
         
      <input 
        type="text" 
        placeholder="Search..." 
        value={searchTerm} 
        onChange={handleSearchChange} 
        className="search-input" 
      />
  
    </div>
  );
};

export default SearchContainer;
