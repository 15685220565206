import React, { useState } from "react";
import "./Login.css";
import myImage from "../CWSMANAGER LOGO.png"
 
import { fetchData } from "../../axios_URL";
 

function LoginForm({onLogin}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [formData, setFormData] = useState({
    usernameoremail: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetchData(
        "post",
        "/login",
        {
          usernameoremail: formData.usernameoremail,
          password: formData.password,
        },
        setError
      );
 
      localStorage.setItem("token", response.data.token);

 
      setIsAuthenticated(true);
      
      onLogin();
      window.location.href = "/";
    
    } catch (error) {
      setError("Invalid username or password");
    }
  };
  if (isAuthenticated) {
    return 
  }
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
     
      <div className="App">

      <div className="login-img">
            <img src={myImage} alt="My Image" />
          </div>
      <header className="App-header">
   
        <div className="login-container">
         
          <div className="login-box">
            <h2>Willkommen bei CWS Manager</h2>
       
          {error && <p className="error-message">{error}</p>}
          <form className="login-form" onSubmit={handleLogin}> 
              <div className="input-group">
                <label htmlFor="username">Benutzername:</label>
                
                <input
                type="text"
                id="usernameoremail"
                name="usernameoremail"
                value={formData.usernameoremail}
                onChange={handleChange}
                required
              />
              </div>
              <div className="input-group">
                <label htmlFor="password">Password:</label>
                <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              </div>
              <div className="actions">
                <a href="#" className="forgot-password">Passwort vergessen?</a>
                <button type="submit" className="login-button">Einlogen</button>
              </div>
            </form>
          </div>
          <div className="help">
            <a href="#">Hilfe?</a>
          </div>
        </div>
      </header>
    </div>

    </>
  );
}

export default LoginForm;