import React, { useEffect, useState } from "react";
import { fetchData } from "../../axios_URL";
import "./EditMyDetails.css";

const EditMyDetails = () => {
  const [employee, setEmployee] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone_number: "",
    hireDate: "",
    birthDate: "",
    person_number: "",
    jobTitle: "",
    nationality: "",
    vacationdays: 0,
    department: null,
  });

  const [passwords, setPasswords] = useState({
    passwordold: "",
    passwordnew: "",
    confirmnew: "",
  });

  const [message, setMessage] = useState("");

  // Fetch employee details on component mount
  useEffect(() => {
    fetchData("get", "/api/employees/getEmployee")
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
      });
  }, []);

  // Handle input changes for employee form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  // Handle input changes for password form
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  // Handle form submit to update employee details
  const handleUpdateDetails = (e) => {
    e.preventDefault();
    fetchData("put", "/api/employees/mydetails", employee)
      .then((response) => {
        setMessage("Details updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating details:", error);
        setMessage("Failed to update details.");
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (passwords.passwordnew !== passwords.confirmnew) {
      setMessage("New password and confirmation do not match.");
      return;
    }

    fetchData("post", "/user/changepassword", {
      passwordold: passwords.passwordold,
      passwordnew: passwords.passwordnew,
    })
      .then((response) => {
        setMessage("Password changed successfully!");
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        setMessage("Failed to change password.");
      });
  };

  return (
    <div className="edit-container">
      {employee && (
        <>
          <h2 className="edit-header">Edit My Details</h2>
          <form onSubmit={handleUpdateDetails} className="edit-form">
            <div className="edit-form-group">
              <label>First Name</label>
              <input
                type="text"
                name="firstname"
                value={employee.firstname}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Last Name</label>
              <input
                type="text"
                name="lastname"
                value={employee.lastname}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={employee.email}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Telephone Number</label>
              <input
                type="text"
                name="telephone_number"
                value={employee.telephone_number}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Hire Date</label>
              <input
                type="date"
                name="hireDate"
                value={employee.hireDate}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Birth Date</label>
              <input
                type="date"
                name="birthDate"
                value={employee.birthDate}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Person Number</label>
              <input
                type="text"
                name="person_number"
                value={employee.person_number}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Job Title</label>
              <input
                type="text"
                name="jobTitle"
                value={employee.jobTitle}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Nationality</label>
              <input
                type="text"
                name="nationality"
                value={employee.nationality || ""}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <label>Vacation Days</label>
              <input
                type="number"
                name="vacationdays"
                value={employee.vacationdays}
                onChange={handleInputChange}
                className="edit-input"
              />
            </div>
            <div className="edit-form-group">
              <button type="submit" className="edit-submit-button">Update Details</button>
            </div>
          </form>
        </>
      )}

      <h2 className="edit-header">Change Password</h2>
      <form onSubmit={handleChangePassword} className="edit-form">
        <div className="edit-form-group">
          <label>Old Password</label>
          <input
            type="password"
            name="passwordold"
            value={passwords.passwordold}
            onChange={handlePasswordChange}
            className="edit-input"
          />
        </div>
        <div className="edit-form-group">
          <label>New Password</label>
          <input
            type="password"
            name="passwordnew"
            value={passwords.passwordnew}
            onChange={handlePasswordChange}
            className="edit-input"
          />
        </div>
        <div className="edit-form-group">
          <label>Confirm New Password</label>
          <input
            type="password"
            name="confirmnew"
            value={passwords.confirmnew}
            onChange={handlePasswordChange}
            className="edit-input"
          />
        </div>
        <div className="edit-form-group">
          <button type="submit" className="edit-submit-button">Change Password</button>
        </div>
      </form>

      {message && <p className="edit-message">{message}</p>}
    </div>
  );
};

export default EditMyDetails;
