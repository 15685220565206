import React, { useState, useEffect } from 'react';
import './Nav.css';
import myImage from "../CWSMANAGER LOGO.png";
import Logout from "../Auth/Logout";
import { fetchData } from "../../axios_URL";
import { NavLink } from 'react-router-dom'; // Import NavLink for navigation

const Nav = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [Name, setName] = useState();

  useEffect(() => {
    fetchData('GET', '/user/getname', null)
      .then((response) => {
        setName(response.data);
      })
      .catch((error) => {
        console.error('Error fetching admins:', error);
      });
  }, []);

  const handleLogoutClick = () => {
    setShowLogoutModal(true); // Show the modal
  };
 
  const handleCloseModal = () => {
    setShowLogoutModal(false); // Hide the modal
  };

  const handleLogout = () => {
    setShowLogoutModal(false);  
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo">
          <img src={myImage} alt="My Image" />
        </div>
        
        {/* Container for all NavLink components */}
        <div className="nav-links-container">
          <ul className="nav-links">
          <li className="nav-left">
              <NavLink to="/workshop-planning" className="nav-button" activeClassName="active-link">Werkstattplanung</NavLink>
            </li>
            <li className="nav-left">
              <NavLink to="/mitarbeiter" className="nav-button"
              
              
              activeClassName="active-link"
              
              
              >Personal</NavLink>
            </li>
            <li className="nav-left">
              <NavLink to="/rental" className="nav-button" activeClassName="active-link">Mietwagen</NavLink>
            </li>
            <li className="nav-left">
              <NavLink to="/checklists" className="nav-button" activeClassName="active-link">Checklisten</NavLink>
            </li>
            <li className="nav-left">
              <NavLink to="/sales-tools" className="nav-button" activeClassName="active-link">Vertriebstool</NavLink>
            </li>
            <li className="nav-left">
              <NavLink to="/promotion" className="nav-button" activeClassName="active-link">Verkaufsförderung</NavLink>
            </li>
          </ul>
        </div>
        
        <ul className="nav-right">
          <li className="nav-item">
           
            <NavLink to="/EditMyDetails" className="nav-button" activeClassName="active-link">{Name}</NavLink>

          </li>
          <li className="nav-item">
            <button className="logout-button" onClick={handleLogoutClick}>
              Ausloggen
            </button>
          </li>
        </ul>
      </nav>

      {/* Show the Logout modal when button is clicked */}
      {showLogoutModal && (
        <Logout onLogout={handleLogout} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default Nav;
