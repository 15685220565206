import React, { useState, useEffect } from 'react';
import Card from '../StaticItems/Card';
import './Mitarabeiter.css';
import { useNavigate } from 'react-router-dom';
import SearchContainer from '../StaticItems/SearchContainer';
import AddEmployee from './AddEmployee'; // Import the modal component
import { fetchData } from "../../axios_URL";

const ErrorPopup = ({ message, onClose }) => (
  <div className="error-popup">
    <div className="error-popup-content">
      <h4>Fehler</h4>
      <p>{message}</p>
      <button onClick={onClose} className="error-close-button">Schließen</button>
    </div>
  </div>
);

function Mitarabeiter() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [admins, setAdmins] = useState([]); // State for admins
  const [employees, setEmployees] = useState([]); // State for employees
  const [error, setError] = useState("");


  useEffect(() => {
    fetchData('GET', '/test/admins', null)
      .then((response) => {
        setAdmins(response.data);
      })
      .catch((error) => {
        console.error('Error fetching admins:', error);
      });
  }, []);

  // Fetch employees from API
  useEffect(() => {
    const cachedEmployees = localStorage.getItem('employees');
    
    {
      // Fetch employees from API
      fetchData('GET', '/test/employees', null)
        .then((response) => {
          setEmployees(response.data);
          // Cache employees data in local storage
          localStorage.setItem('employees', JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error('Error fetching employees:', error);
        });
    }
  }, []);



  const handleAddItem = () => {
    setShowModal(true); // Show modal on button click
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide modal
  };
  const handleExit = () => {

    navigate("/");
  };
  const handleSaveEmployee = async (event, employee, apiEndpoint) => {
    event.preventDefault();

    try {
      const response = await fetchData(
        "POST",
        apiEndpoint,
        employee,
        setError
      );

      console.log("Employee saved successfully:", response.data);
      setShowModal(false); // Hide modal
    } catch (error) {
      setError("Fehler beim Speichern des Mitarbeiters");
    }
  };

  const handleCloseError = () => {
    setError(""); // Clear error message and close popup
  };

  return (
    <>
      <div className='SearchContainer'>
        <SearchContainer onAddItem={handleAddItem} onExit={handleExit} />
      </div>
      <div className="mcontainer">
        <div className="mcontainer2">
          <div className="grid-container2">

            {admins.length > 0 ? (
              admins.map((admin) => (
                <Card
                  key={admin.id}
                  number={"0"}
                  title={`${admin.firstname} ${admin.lastname}`}
                  items={["Admin"]}

                  navigateTo={`/mitarbeiter/${admin.id}`}
                />
              ))
            ) : (
              <p>No admins found.</p>
            )}
          </div>
          <div className="grid-container">
            {employees.length > 0 ? (
              employees.map((employee) => {
                // Determine the color class based on the employee's status
                let statusClass = "";
                if (employee.status === "Present") {
                  statusClass = "status-present";
                } else if (employee.status === "pause") {
                  statusClass = "status-pause";
                } else {
                  statusClass = "status-absent"; // For "Absent" and other statuses
                }

                return (
                  <Card
                    key={employee.id}
                    number={"0"}
                    title={
                      <>
               
                        {`${employee.firstname} ${employee.lastname}`}
                        <span className={`status-circle ${statusClass}`}></span>
                      </>
                    }
                    items={[employee.department, employee.jobTitle]}
                    thename=  {`${employee.firstname} ${employee.lastname}`}
                    navigateTo={`/mitarbeiter/${employee.id}`}
                  />
                );
              })
            ) : (
              <p>No employees found.</p>
            )}
          </div>

        </div>
      </div>

      {showModal && (
        <AddEmployee onClose={handleCloseModal} onSave={handleSaveEmployee} />
      )}
      {error && <ErrorPopup message={error} onClose={handleCloseError} />}
    </>
  );
}

export default Mitarabeiter;
