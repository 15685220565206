import React, { useEffect, useState } from "react";
import { fetchData } from "../../axios_URL";
import "./EmployeePaper.css";

const EmployeePaper = ({ id }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  // Fetch messages for the employee on component mount
  useEffect(() => {
    fetchEmployeeMessages();
  }, [id]);

  // Function to fetch all messages for the employee
  const fetchEmployeeMessages = () => {
    fetchData("get",`/test/getemployeemessages/${id}`)
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to fetch messages.");
      });
  };

  // Handle form submission for writing a message
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newMessage) {
      setError("Message cannot be empty.");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    // Call the API to write a message as an admin
    fetchData("post", `/test/writeadmin/${id}`, { message: newMessage })
      .then((res) => {
        setNewMessage(""); // Clear the input
        fetchEmployeeMessages(); // Refresh the message list
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to send message.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="employee-paper-container">
 

      {/* Display messages in chat style */}
      <div className="messages-list">
        {messages.length > 0 ? (
          messages.map((msg) => (
            <div
              key={msg.id}
              className={`message-item ${msg.isemployeewrite ? "employee-message" : "admin-message"}`}
            >
              <div className="message-content">
                <strong>{msg.nameofwriter}</strong>
                <p>{msg.message}</p>
                <small>{msg.isread ? "✓✓" : "✓"}</small>
              </div>
            </div>
          ))
        ) : (
          <p>No messages found.</p>
        )}
      </div>

      {/* Form to write a message */}
      <form onSubmit={handleSubmit} className="message-form">
        <textarea
          placeholder="Write a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          rows={4}
          disabled={isSubmitting}
        />
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Sending..." : "Send Message"}
        </button>
      </form>

      {/* Display error if any */}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default EmployeePaper;
