import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Card.css';

const Card = ({ number, title, items, navigateTo ,state,thename}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(navigateTo,{
      state: {
        thename
      }
   
    });
  };

  return (
    <div className="card" onClick={handleCardClick}>
      <div className="card-header">
      {number !== "0" && (
          <span className="card-number">{number}</span>
        )}
        <h3 className="card-title">{title}</h3>
      </div>
      <ul className="card-items">
        {items.map((item, index) => (
          <li key={index} className="card-item">
            <span className="circle"></span>
            {item}
          </li>
        ))}
     
      </ul>
    </div>
  );
};
 
  
export default Card;
