import React from "react";
import "./Logout.css";

function Logout({ onLogout, onClose }) {
  const handleLogout = async (event) => {
    event.preventDefault();
    
    localStorage.removeItem("token");
    
    onLogout();  
    onClose();  
    window.location.href = "/";
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h3>Are you sure you want to log out?</h3>
        <div className="modal-buttons">
          <button onClick={handleLogout} className="confirm-button">
            Log Out
          </button>
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default Logout;
